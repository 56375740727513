




























































































































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";

@Component
export default class PublicacionActComponent extends Mixins(FormValidator) {
  private nombreWeb: any = "";
  private dataActividad: any = "";
  private slide = 1;

  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getInfoActividad();
    }
  }

  private getInfoActividad() {
    this.$axios
      .get("actividades/detalle?nombre_web=" + this.nombreWeb)
      .then((res: any) => {
        this.dataActividad = res.data;

        if (this.dataActividad.foto != null) {
          this.dataActividad.foto =
            "https://d1rofaen6s22p4.cloudfront.net/" + this.dataActividad.foto;
        } else {
            this.dataActividad.foto = require("@/assets/logos/logo-default.png");
          }

        if (this.dataActividad.foto2) {
          this.dataActividad.foto2 =
            "https://d1rofaen6s22p4.cloudfront.net/" + this.dataActividad.foto2;
        }

        if (this.dataActividad.foto3) {
          this.dataActividad.foto3 =
            "https://d1rofaen6s22p4.cloudfront.net/" + this.dataActividad.foto3;
        }

      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private descripcionFechasEvento(fecha_inicio, fecha_termino) {
    var anho_inicio = fecha_inicio.substring(0, 4);
    var mes_inicio: any = parseInt(fecha_inicio.substring(5, 7), 10);
    var dia_inicio = fecha_inicio.substring(8, 10);
    var hora_inicio = fecha_inicio.substring(11, 16);

    var anho_termino = fecha_termino.substring(0, 4);
    var mes_termino: any = parseInt(fecha_termino.substring(5, 7), 10);
    var dia_termino = fecha_termino.substring(8, 10);
    var hora_termino = fecha_termino.substring(11, 16);

    if (mes_inicio == 1) {
      mes_inicio = "Enero";
    } else if (mes_inicio == 2) {
      mes_inicio = "Febrero";
    } else if (mes_inicio == 3) {
      mes_inicio = "Marzo";
    } else if (mes_inicio == 4) {
      mes_inicio = "Abril";
    } else if (mes_inicio == 5) {
      mes_inicio = "Mayo";
    } else if (mes_inicio == 6) {
      mes_inicio = "Junio";
    } else if (mes_inicio == 7) {
      mes_inicio = "Julio";
    } else if (mes_inicio == 8) {
      mes_inicio = "Agosto";
    } else if (mes_inicio == 9) {
      mes_inicio = "Septiembre";
    } else if (mes_inicio == 10) {
      mes_inicio = "Octubre";
    } else if (mes_inicio == 11) {
      mes_inicio = "Noviembre";
    } else {
      mes_inicio = "Diciembre";
    }
    if (mes_termino == 1) {
      mes_termino = "Enero";
    } else if (mes_termino == 2) {
      mes_termino = "Febrero";
    } else if (mes_termino == 3) {
      mes_termino = "Marzo";
    } else if (mes_termino == 4) {
      mes_termino = "Abril";
    } else if (mes_termino == 5) {
      mes_termino = "Mayo";
    } else if (mes_termino == 6) {
      mes_termino = "Junio";
    } else if (mes_termino == 7) {
      mes_termino = "Julio";
    } else if (mes_termino == 8) {
      mes_termino = "Agosto";
    } else if (mes_termino == 9) {
      mes_termino = "Septiembre";
    } else if (mes_termino == 10) {
      mes_termino = "Octubre";
    } else if (mes_termino == 11) {
      mes_termino = "Noviembre";
    } else {
      mes_termino = "Diciembre";
    }
    var response = "";
    if (mes_inicio == mes_termino) {
      if (dia_inicio == dia_termino) {
        response =
          dia_inicio +
          " de " +
          mes_termino +
          ": " +
          hora_inicio +
          " a las " +
          hora_termino;
      } else {
        response =
          dia_inicio +
          " al " +
          dia_termino +
          " de " +
          mes_termino +
          ": " +
          hora_inicio +
          " a las " +
          hora_termino;
      }
    } else {
      response =
        "Desde el " +
        dia_inicio +
        " de " +
        mes_inicio +
        " a las " +
        hora_inicio +
        " Hasta el " +
        dia_termino +
        " de " +
        mes_termino +
        " a las " +
        hora_termino;
    }
    return response;
  }
}

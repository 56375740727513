import { render, staticRenderFns } from "./PublicacionView.vue?vue&type=template&id=6b2fa246&scoped=true&"
import script from "./PublicacionView.vue?vue&type=script&lang=ts&"
export * from "./PublicacionView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b2fa246",
  null
  
)

export default component.exports
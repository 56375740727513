






import { Vue, Component, Prop } from "vue-property-decorator";
import PublicacionActComponent from "@/components/Actividades/PublicacionActComponent.vue";

@Component({
  components: {
    PublicacionActComponent
  }
})
export default class PublicacionView extends Vue {
  private mounted() {}
}
